<template>
    <index></index>
</template>

<script>
import index from '@/components/index.vue'
export default {
    name: 'indexView',
    components: {index}
}
</script>

<style scoped>
</style>