<template>
  <aboutMe/>
</template>

<script>
import aboutMe from '@/components/AboutMe.vue'

export default {
  name: "aboutMeView",
  components: {
    aboutMe
  }
}
</script>

<style scoped>

</style>