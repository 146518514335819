<template>
  <navBar current-page="certificates"/>

  <div class="title">
    MY CERTIFICATIONS
  </div>


  <br>


  <div class="flex-container">


    <template v-for="(certificate, index) in certificates" :key="index">
      <div class="flip">
        <div class="front">
          <img :src="certificate[3]" class="cert-img" alt="certificate" />
        </div>
        <div class="back">
          <div class="cert-head">{{ certificate[0] }}</div>
              <div class="cert-date">{{ certificate[1] }}</div>
              <div class="cert-desc">{{ certificate[2] }}</div>

              <template v-if="certificate[4] != 'skip'">
                <div class="cert-verify">
                  <a :href="certificate[4]" target="_blank">Link to Verify</a>
                </div>
              </template>
        </div>
      </div>
    </template>



  </div>


<br>

  <router-link :to="{name: 'cert2View'}">
      <button class="cert-next">
      NEXT
      </button>
  </router-link>


<foot-bar />

  <div class="hr-rule-white"></div>
  <a href="#" class="back-to-top" @click="scrollToTop">Back to top</a>
  <div class="hr-rule-white"></div>
</template>


<script>

import navBar from "@/components/navBar";
import footBar from "@/components/footBar";

export default {
  name: 'testCompView',
  data() {
    return {
      certificates: [
          [
            'Diploma in Data Science (IIT-M)',
            'December 2023',
            'Completed diploma in data science with 6 courses + 2 projects in my BS degree',
        require('@/assets/certificates/diploma_data_science.png'),
            'https://app.onlinedegree.iitm.ac.in/document_verification/012360c10272a3444cbb6da522f37ad0053263bca3bddf868bf346f68273febb'
          ],
          [
            'Intro to Machine Learning',
            'October 2023',
            'Completed and topped a 12 weeks long course on mathematical machine learning.',
            require('@/assets/certificates/intro_to_ml_nptel.png'),
            'https://nptel.ac.in/noc/E_Certificate/NPTEL23CS98S73430064220336994'
          ],
          [
            'Data Analytics with Python',
            'April 2023',
            'Completed a 12 weeks long course on Data Analytics, comprising of topics from statistics.',
            require('@/assets/certificates/DA_Python_NPTEL.jpeg'),
            'https://nptel.ac.in/noc/E_Certificate/NPTEL23CS08S1344151904244305'
          ],
          [
            'Privacy and Security in OSM',
            'April 2023',
            'Completed a 12 weeks long course on the importance of privacy and security in online social media',
            require('@/assets/certificates/Privacy_NPTEL.jpeg'),
            'https://nptel.ac.in/noc/E_Certificate/NPTEL23CS13S3344720304244305'
          ],
          [
            'Diploma in Programming (IIT-M)',
            'December 2022',
            'Completed diploma in programming(6 courses + 2 projects) in my BS Degree.',
            require('@/assets/certificates/diploma_cert.png'),
            'https://app.onlinedegree.iitm.ac.in/document_verification/b99309d732ac8bd4718f688cfb38c41ef6f59472b9df782199e2b00649435c27'
          ],
          [
            'Teaching Assistant - MAD I & II',
            'December 2022',
            'Conducted viva of ~20 students to evaluate their projects.',
            require('@/assets/certificates/AppDevTA_sept22.png'),
            'skip'
          ],
          [
            'Academic Mentor - DBMS',
            'December 2022',
            'Mentored and supervised 80+ students in the database and management course during the September 2022 term.',
            require('@/assets/certificates/DBMSMentor.png'),
            'skip'
          ],
          // CHANGE BELOW
          [
            'Google Data Analytics',
            'September 2022',
            'Completed a bundle of 8 courses related to data analytics.',
            require('@/assets/certificates/GoogleDataAnalytics.png'),
            'https://www.coursera.org/account/accomplishments/specialization/R3C4DG4B7UG8'
          ],
          [
            'Tableau Intermediate',
            'February 2022',
            'Official course from Tableau\'s e-learning platform for intermediate usage of tableau.',
            require('@/assets/certificates/TableauIntermediate.jpeg'),
            'https://verify.skilljar.com/c/r46gm8f5542c'
          ],
          [
            'IIT Madras Foundational',
            'December 2021',
            'Completed the foundational level(8 courses) at my BS degree.',
            require('@/assets/certificates/IIT_Found.png'),
            'https://app.onlinedegree.iitm.ac.in/document_verification/63872563c3c30e9a2cc264431846a2459b642befee62c2b26ab54bdefd723dc2'
          ],
          [
            'ML Pipeline with Azure ML Studio',
            'May 2021',
            'Created a boosted decision tree model from scratch on Azure cloud platform.',
            require('@/assets/certificates/Azure_ML.png'),
            'https://www.coursera.org/account/accomplishments/verify/6L4HTDN7M3NB'
          ],


          // [
          //   'CertName',
          //   'date',
          //   'description',
          //   'imgURL',
          //   'verify'
          // ],

      ]

  }
  },
  components: {
    navBar,
    footBar
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  },
  mounted() {
    localStorage.currentPage = 'cert';
    setTimeout(
    function(){window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })}, 500)
  }


}
</script>

<style scoped>

.cert-img{
  width: 450px;
  height: 330px;
  border-radius: 10px;
  border: black 2px solid;
}

.flip {
  position: relative;
}
.flip > .front,
.flip > .back {
  display: block;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 1.5s;
  transition-property: transform, opacity;
}
.flip > .front {
  transform: rotateY(0deg);
}
.flip > .back {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg);
}
.flip:hover > .front {
  transform: rotateY(180deg);
  opacity: 0;
}
.flip:hover > .back {
  opacity: 1;
  transform: rotateY(0deg);
}
.flip.flip-vertical > .back {
  transform: rotateX(-180deg);
}
.flip.flip-vertical:hover > .front {
  transform: rotateX(180deg);
}
.flip.flip-vertical:hover > .back {
  transform: rotateX(0deg);
}

.flip {
  position: relative;
  display: inline-block;
  margin: 15px 15px 15px 15px;
  width: 450px;
}
.flip > .front,
.flip > .back {
  display: block;

  width: inherit;
  background-size: cover !important;
  background-position: center !important;
  height: 330px;
  /*padding: 1em 2em;*/

  border-radius: 10px;
}
.flip > .front p,
.flip > .back p {
  font-size: 0.9125rem;
  line-height: 160%;
  color: #999;
}

.back{
  background-image: url("@/assets/bg_img_cert.svg");
}



.hr-rule-white{
  border: 2px solid white;
  display: block;
  width: 880px;
  margin-top: 25px;
}

.hr-rule-white{
  border: 2px solid white;
  display: block;
  width: 880px;
  margin-top: 25px;
}

#BDM-Proj{
  background-image: url('@/assets/projects/supermartDA.svg');

}
#portfolio{
  background-image: url('@/assets/projects/portfolio.svg');
  background-size: 600px;
}
#stuportfolio{
  background-image: url('@/assets/projects/stuportfolio.svg');
  background-size: 600px;
}
#easylife{
  background-image: url('@/assets/projects/easylife.svg');
  background-size: 600px;
}
#lyfRecord1{
  background-image: url('@/assets/projects/lyfRecord1.0.svg');
  background-size: 600px;
}
#lyfRecord2{
  background-image: url('@/assets/projects/lyfRecord2.0.svg');
  background-size: 600px;
}
#gradebook{
  background-image: url('@/assets/projects/gradebook.svg');
  background-size: 600px;
}
#progHTML{
  background-image: url('@/assets/projects/progHTML.svg');
  background-size: 600px;
}

a{
  text-decoration: none;
}

.back-to-top{
  font-family: Jura, 'Source Sans Pro';
  font-weight: 500;
  font-size: 23px;
  color: black;
  margin-top: 5px;
  margin-bottom: 1%;
  text-align: center;
  margin-left: 0.5%;
  text-transform: uppercase;
}

.back-to-top a{

}

.back-to-top:hover{
  color: deeppink;
}




.title{
  text-align: center;
  font-family: Jura, 'Source Sans Pro';
  color: black;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 2%;

  font-size: 38px;
}




.flex-container {

  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;

}

.flex-container a{
  color: black;
  text-decoration: none;
}

.flex-container a:hover{

  color: deeppink;
}


.cert-head{
  font-family: Jura, 'Source Sans Pro';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  margin: 20px 10px 0px 10px;
}

.cert-date{
  font-family: Chivo, 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  margin: 10px 10px 10px 10px;
}

.cert-desc{
  font-family: Chivo, 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  margin: 20px 10px 10px 10px;
}

.cert-verify{
  margin-top: 22%;
  text-transform: uppercase;
}

.cert-verify a{
  color: black;
  font-family: Jura, 'Source Sans Pro';
  font-weight: 700;
  font-size: 26px;
  text-decoration: none;
}

.cert-verify a:hover{
  color: black;
  border-bottom: solid black 5px;
  padding: 2%;
}

.cert-next {
  color: black;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size: 20px;
  background: white;
  padding: 10px 25px 10px 25px;
  border: solid black 2px;
  text-decoration: none;
}

.cert-next:hover {
  background: deeppink;
  color: white;
  padding: 12px 27px 12px 27px;
  border: none;
  text-decoration: none;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 1100px) {

 .flex-container {
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
  }

  .cert-next{
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .flip{
    width: 700px;
  }

  .flip > .front,
  .flip > .back {
    height: 530px;
  }

  .cert-img{
    width: 700px;
    height: 530px;
  }


  .cert-head{
    font-size: 36px;
    margin-top: 20px;
  }

  .cert-date{
    font-size: 25px;
    margin-top: 15px;
  }

  .cert-desc{
    font-size: 24px;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 25px;
  }


  .cert-verify{
    margin-top: 28%;
  }

  .cert-verify a{
    font-size: 36px;
  }



}


</style>
