<template>
  <navBar current-page="projects"/>

  <div class="title">
    Projects
  </div>


  <br>


  <div class="flex-container">


    <template v-for="(proj, index) in projects" :key="index">
      <div class="flip">
        <div class="front" :id="proj[0]">

        </div>
        <div class="back">
          <div class="cert-head">{{ proj[2] }}</div>
              <div class="cert-date">{{ proj[3] }}</div>
              <div class="cert-desc">{{ proj[4] }}</div>
              <div class="cert-skills">{{ proj[5] }}</div>
              <template v-if="proj[6] != 'skip'">
                <div class="cert-verify">
                  <a :href="proj[6]" target="_blank">Link to Verify</a>
                </div>
              </template>
        </div>
      </div>
    </template>



  </div>


<br>

  <router-link :to="{name: 'proj2View'}">
      <button class="cert-next">
      NEXT
      </button>
  </router-link>


<foot-bar />

  <div class="hr-rule-white"></div>
  <a href="#" class="back-to-top" @click="scrollToTop">Back to top</a>
  <div class="hr-rule-white"></div>
</template>


<script>

import navBar from "@/components/navBar";
import footBar from "@/components/footBar";

export default {
  name: 'testCompView',
  data() {
    return {
      projects: [

          [
          "MLP-Proj",
          "flex-item-left",
          "Taxi Fare Prediction",
          "December 2023",
          "Created a predictive ML model to learn trends from the data to predict the taxi fare.",
          "Skills: Machine Learning, Feature Engineering, SK-Learn, Pandas, Numpy",
          "https://github.com/Ruhil-DS/Taxi-fare-prediction"
        ],
        [
          "BDM-Proj",
          "flex-item-left",
          "Data Analysis of a Supermart",
          "April 2023",
          "Analysed the data of a super-mart in my locality for my BDM course project.",
          "Skills: Data Analysis, Excel, Data Visualisation, Tableau, Report making",
          "https://github.com/Ruhil-DS/BDM-project"
        ],
         [
           "portfolio",
           "flex-item-right",
           "Vue.JS Portfolio",
           "January 2023",
           "Made my very own portfolio using Vue.JS framework. You are currently viewing it :')",
           "Skills: HTML, CSS, JS, VueJS",
           "skip"],
          [
           "easylife",
           "flex-item-left",
           "EasyLife ChatBot",
           "December 2022",
           "Made my very first multi-purpose chatbot using Google's Dialogflow and Flask.",
           "Skills: Google's DialogFlow, Flask, BeautifulSoup (web scraping)",
           "https://github.com/Ruhil-DS/Easy-Life-Chatbot"],

          [
              "lyfRecord2",
              "flex-item-right",
              "Quantified Self App",
              "May 2022",
              "A Vue.JS based Single Page Application (SPA). This is a v2.0 of my Quantified-Self App.",
              "Skills: Python(Flask, SQL-Alchemy), APIs, VueJS, Celery, Redis",
              "https://github.com/Ruhil-DS/lyf-record/"
          ],
          [
              "lyfRecord1",
              "flex-item-left",
              "Quantified Self App",
              "January 2022",
              "Flask based Quantified-Self Application to create trackers and log values to them. Allows the CRUD operations.",
              "Skills: Flask, SQL-Alchemy, Jinja, HTML, CSS, JS",
              "https://github.com/Ruhil-DS/QuantifiedSelfApp-FLASK"
          ],
           [
              "gradebook",
              "flex-item-right",
              "GradeBook",
              "February 2021",
              "A GradeBook Application to keep student's record along with the subjects. Allows CRUD operation on both, students as well as courses DB",
              "Skills: Flask, SQL-Alchemy, HTML",
              "https://github.com/Ruhil-DS/Gradebook-CRUD"
          ],
          [
              "stuportfolio",
              "flex-item-left",
              "HTML/CSS Student's Portfolio",
              "January 2022",
              "Created 2 versions of student's portfolio. One with CSS and another one without. Started my HTML/CSS journey from here",
              "Skills: HTML5, CSS",
              "https://github.com/Ruhil-DS/HTML-CSS"
          ],
          [
              "progHTML",
              "flex-item-right",
              "Programmatic HTML - PyHTML",
              "January 2022",
              "Programmatic approach for generating HTML files. Used a python module \"PyHTML\" to achieve the following.",
              "Skills: Python, PyHTML, HTML",
              "https://github.com/Ruhil-DS/PyHTML"
          ],

          // [
          //     "ID",
          //     "flex-item-right",
          //     "name",
          //     "date",
          //     "desc",
          //     "skills",
          //     "link"
          // ],


      ]


  }
  },
  components: {
    navBar,
    footBar
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  },
  mounted() {
    localStorage.currentPage = 'proj';
    setTimeout(
    function(){window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })}, 500)
  }


}
</script>

<style scoped>


.flip {
  position: relative;
}
.flip > .front,
.flip > .back {
  display: block;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 1.5s;
  transition-property: transform, opacity;
}
.flip > .front {
  transform: rotateY(0deg);
}
.flip > .back {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg);
}
.flip:hover > .front {
  transform: rotateY(180deg);
}
.flip:hover > .back {
  opacity: 1;
  transform: rotateY(0deg);
}
.flip.flip-vertical > .back {
  transform: rotateX(-180deg);
}
.flip.flip-vertical:hover > .front {
  transform: rotateX(180deg);
}
.flip.flip-vertical:hover > .back {
  transform: rotateX(0deg);
}

.flip {
  position: relative;
  display: inline-block;
  margin: 10px 10px 10px 10px;
  width: 450px;
}
.flip > .front,
.flip > .back {
  display: block;

  width: inherit;
  background-size: cover !important;
  background-position: center !important;
  height: 330px;
  padding: 1em 2em;

  border-radius: 10px;
}
.flip > .front p,
.flip > .back p {
  font-size: 0.9125rem;
  line-height: 160%;
  color: #999;
}

.back{
  background-image: url("@/assets/bg_img_cert.svg");
}



.hr-rule-white{
  border: 2px solid white;
  display: block;
  width: 880px;
  margin-top: 25px;
}

.hr-rule-white{
  border: 2px solid white;
  display: block;
  width: 880px;
  margin-top: 25px;
}

#MLP-Proj{
  background-image: url('@/assets/projects/taxi_fare.svg');
}
#BDM-Proj{
  background-image: url('@/assets/projects/supermartDA.svg');

}
#portfolio{
  background-image: url('@/assets/projects/portfolio.svg');
  background-size: 600px;
}
#stuportfolio{
  background-image: url('@/assets/projects/stuportfolio.svg');
  background-size: 600px;
}
#easylife{
  background-image: url('@/assets/projects/easylife.svg');
  background-size: 600px;
}
#lyfRecord1{
  background-image: url('@/assets/projects/lyfRecord1.0.svg');
  background-size: 600px;
}
#lyfRecord2{
  background-image: url('@/assets/projects/lyfRecord2.0.svg');
  background-size: 600px;
}
#gradebook{
  background-image: url('@/assets/projects/gradebook.svg');
  background-size: 600px;
}
#progHTML{
  background-image: url('@/assets/projects/progHTML.svg');
  background-size: 600px;
}

a{
  text-decoration: none;
}

.back-to-top{
  font-family: Jura, 'Source Sans Pro';
  font-weight: 500;
  font-size: 23px;
  color: black;
  margin-top: 5px;
  margin-bottom: 1%;
  text-align: center;
  margin-left: 0.5%;
  text-transform: uppercase;
}

.back-to-top a{

}

.back-to-top:hover{
  color: deeppink;
}




.title{
  text-align: center;
  font-family: Jura, 'Source Sans Pro';
  color: black;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 2%;

  font-size: 38px;
}




.flex-container {

  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

.flex-container a{
  color: black;
  text-decoration: none;
}

.flex-container a:hover{

  color: deeppink;
}


.cert-head{
  font-family: Jura, 'Source Sans Pro';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  margin-top: 10px;
}

.cert-date{
  font-family: Chivo, 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.cert-desc{
  font-family: Chivo, 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.cert-skills{
  font-family: Chivo, 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.cert-verify{
  margin-top: 10%;
  text-transform: uppercase;
}

.cert-verify a{
  color: black;
  font-family: Jura, 'Source Sans Pro';
  font-weight: 700;
  font-size: 26px;
  text-decoration: none;
}

.cert-verify a:hover{
  color: black;
  border-bottom: solid black 5px;
  padding: 2%;
}

.cert-next {
  color: black;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size: 20px;
  background: white;
  padding: 10px 25px 10px 25px;
  border: solid black 2px;
  text-decoration: none;
}

.cert-next:hover {
  background: deeppink;
  color: white;
  padding: 12px 27px 12px 27px;
  border: none;
  text-decoration: none;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 1100px) {

  .flex-container {
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
  }

  .cert-next{
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .flip{
    width: 700px;
  }

  .flip > .front,
  .flip > .back {
    height: 500px;
  }

  .cert-head{
    font-size: 36px;
    margin-top: 20px;
  }

  .cert-date{
    font-size: 25px;
    margin-top: 15px;
  }

  .cert-desc{
    font-size: 24px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .cert-skills{
    margin-top: 20px;
    font-size: 24px;

  }

  .cert-verify{
    margin-top: 10%;
  }

  .cert-verify a{
    font-size: 36px;
  }


}


</style>
