<template>

  <nav class="navbar">
    <div class="name">
      <router-link :to="{name: 'indexView'}">
            PUSHPAK RUHIL
      </router-link>
    </div>

    <div class="menu-items" >
    <ul class="header__links">
            <li class="header__link-wrapper">
                    <router-link class="header__link" :to="{name: 'aboutMeView'}" :style=" currentPage=='aboutMe' && 'color: deeppink;'">
                        About&nbsp;me
                      </router-link>
            </li>
            <li class="header__link-wrapper">
              <router-link class="header__link" :to="{name: 'projectsView'}" :style=" currentPage=='projects' && 'color: deeppink;'">
                      Projects
                    </router-link>
            </li>
            <li class="header__link-wrapper">

                    <router-link class="header__link" :to="{name: 'blogsView'}" :style=" currentPage=='blogs' && 'color: deeppink;'">
                      Blogs&nbsp;&nbsp;
                    </router-link>

            </li>
            <li class="header__link-wrapper">

                    <router-link class="header__link" :to="{name: 'certificatesView'}" :style=" currentPage=='certificates' && 'color: deeppink;'">
                      Certificates
                    </router-link>

            </li>
    </ul>
    </div>

    <div class="hamburger">
<!--      <i class="fas fa-bars"></i>-->
      <i class="fas fa-bars" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"></i>

<div class="offcanvas offcanvas-top" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasTopLabel"></h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">


    <router-link :to="{name: 'aboutMeView'}" :style=" currentPage=='aboutMe' && 'color: deeppink;'">
      About&nbsp;me
    </router-link>
    <br>
    <router-link :to="{name: 'projectsView'}" :style=" currentPage=='projects' && 'color: deeppink;'">
      Projects
    </router-link>
    <br>
    <router-link :to="{name: 'blogsView'}" :style=" currentPage=='blogs' && 'color: deeppink;'">
      Blogs
    </router-link>
    <br>
    <router-link id="last-item" :to="{name: 'certificatesView'}" :style=" currentPage=='certificates' && 'color: deeppink;'">
      Certificates
    </router-link>

  </div>
</div>
    </div>
  </nav>

<div class="hr-rule" />



</template>


<script>
export default {
    name : 'namBar',
    props: ['currentPage'],
    data() {
        return {
          showMenu: true
        }
    },
  methods: {
  },
  mounted() {

  }
}
</script>

<style scoped>

/*----------------------------*/
.btn-close{
  margin-right: 5%;
  margin-top: 3%;
}

.offcanvas-body{
  font-family: Jura, 'Source Sans Pro';
  text-transform: uppercase;
  font-size: 40px;
  text-align: center;
  line-height: 200%;
}

.offcanvas-top{
  height: 30%;

}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.525%;
}



.name {
  margin-left: 6%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 40px;
  color: #000000;
  font-family: Jura, 'Source Sans Pro';
}

.header__links{
  display: flex;
  font-family: Jura, 'Source Sans Pro';
  font-size: 25px;
}
ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
  list-style: none;

}
.header__link {
    padding-right: 3.8rem;
    display: inline-block;
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;

}

.menu-items {
  display: flex;
  font-family: Jura, 'Source Sans Pro';
  font-size: 25px;
}

.menu-items a {
  display: flex;
  margin-right: 20%;
  float: right;
}


.hamburger{
  display: none;
}

@media (max-width: 1700px) and (min-width: 1400px) {
  .header__link {
    font-size: 22px;
  }
  .name{
    font-size: 35px;
  }

}

@media (max-height: 100vw) and (min-width: 100vh) {

  .offcanvas-top{
    height: 100%;
  }
}

@media (max-width: 1400px) {
  .menu-items {
    display: none;
  }

  .name{
    margin-left: 5%;
  }

  .navbar{
    margin-top: 1%;
  }

  .hamburger {
    display: block;
    margin-right: 5.7%;
    font-size: 45px;
  }
  .hr-rule{
    margin-bottom: 30px;
  }
}


/*---------------------------*/


.hr-rule:after {
    border: 2px solid rgb(0, 0, 0);
    content: " ";
    display: block;

    /* border-bottom: 0.5px solid #9E9E9E; */
}

a:link {
    color: black;
    text-decoration: none;

  }

a:visited {
    color: black;
    text-decoration: none;

  }

a:hover {
    color: deeppink;
    text-decoration: none;
  }




</style>