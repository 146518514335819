<template>
  <nav-bar current-page="error" id="navBar"/>
  <img src="../assets/errorpage3.gif" id="error404">

  <foot-bar />
</template>

<script>
import navBar from "@/components/navBar";
import footBar from "@/components/footBar";
export default {
  name: "error",
  components: {
    navBar,
    footBar
  }
}

</script>

<style>

#error404{
    margin-bottom: 8%;
  }

@media (max-width: 1500px){
  #error404{
    margin-bottom: 10%;
  }
  .hr-rule{
    margin-bottom: 0% !important;
  }

}

</style>