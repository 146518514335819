<template>


  <footer class="text-center text-lg-start">
  <!-- Copyright -->
  <div class="text-left p-3">
    <span id="socials-connect">LET'S CONNECT? </span>

      <br class="only-mobile">
      <br class="only-mobile">

    <a href="https://github.com/Ruhil-DS/" target="_blank">
      <i id="last-icon" class="social-icons fa-brands fa-square-github" ></i>
    </a>
    <a href="https://ruhil.medium.com/" target="_blank">
      <i class="social-icons fa-brands fa-medium" ></i>
    </a>
    <a href="mailto:RUHILPUSHPAK@GMAIL.COM" target="_blank">
      <i class="social-icons fa-solid fa-envelope" ></i>
    </a>
    <a href="https://linkedin.com/in/PushpakRuhil/" target="_blank">
      <i class="social-icons fa-brands fa-linkedin"></i>
    </a>
    </div>
  <!-- Copyright -->
</footer>



</template>

<script>
export default {
  name: "footBar"
}
</script>

<style scoped>



/*------------------*/

a{
  color: black;
}

#socials-connect{
  font-size: 28px;
  font-weight: 700;
  font-family: Jura, 'Source Sans Pro';
  padding-left: 5.5%;
  float: left;

}
.social-icons {
  float: right;
  padding-right: 2%;
  font-size: 36px;
}

#last-icon{
  margin-right: 3%;
}

.only-mobile{
  display: none;
}

@media (max-width: 1400px) {
  *{text-align: center;}

  #socials-connect{
    font-size: 25px;
    font-weight: 700;
    font-family: Jura, 'Source Sans Pro', sans-serif;
    float: none;
    margin: 0;
    padding: 0;
    text-align: center;

  }
  .social-icons {
    float: none;
    text-align: center;
    font-size: 45px;
    margin-left: 10px;
    margin-right: 10px;
}



  .only-mobile{
    display: block;
    text-align: center;
  }

  #last-icon{
    margin-right: 0;
    margin-left: 25px;
    text-align: center;
  }


}
</style>