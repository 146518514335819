<template>

  <navBar current-page="blogs"/>

    <br>
    <div class="marquee">
      <img src="../assets/redir.gif" style="width: 35%">
    </div>
    <br>

  <div class="content-center">
    You are being redirected to my Medium profile.
  </div>

  <a href="#" id="go-back-simon" @click="this.$router.go(-1)">Go back</a>


<!--    <footBar />-->
</template>

<script>
import navBar from "@/components/navBar.vue";
import footBar from "@/components/footBar.vue";
export default {
  name: "blogs.vue",
  components: {
    navBar,
    footBar
  },
  methods: {
    redirect(){

      if(localStorage.currentPage == 'blogs'){
       window.location.href = "https://ruhil.medium.com/"
      }
      else{
        null
      }



    },
  },
  mounted() {
    localStorage.currentPage='blogs'
    setTimeout(this.redirect, 5000)
  },

}
</script>

<style scoped>


#go-back-simon:hover{
  color: deeppink;
}

.content-center{
  text-align: center;
  font-family: Chivo, 'Source Sans Pro';
  font-weight: 500;
  font-size: 38px;
  color: black;
  margin-top: 4%;
  margin-left: 8%;
  margin-right: 8%;
}

  #go-back-simon{
    font-size: 32px;
    font-family: Jura, 'Source Sans Pro';
    color: black;
    text-decoration: underline;
    transform: translate(440px, 120px);
  }



.marquee {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.marquee img {
  position: relative;
  left: 0;
  margin: 0;
  padding: 0;
  width: 400px !important;
  animation: marquee 8s linear infinite;
}

@keyframes marquee {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}

@media (max-width: 1000px) {
  .content-center{
    text-align: center;
    margin: 35% 10% 0 10%;
    font-size: 60px;
  }
  #go-back-simon{
    font-size: 60px;
  }

  .marquee {
    width: 100%;
  }
  .marquee img{
    width: 700px !important;
    animation: marquee 5s linear infinite !important;

  }


}

</style>