<template>
  <blogs />
</template>

<script>
import blogs from "@/components/blogs.vue";
export default {
  name: "blogsView.vue",
  components: {
    blogs
  }
}
</script>

<style scoped>

</style>