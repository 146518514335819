<template>
    <navBar current-page="index"/>

    <div class="grid text-center">
        <div class="row">
            <div class="col work-header">

                <div class="container">
                  I'M
                  <template v-if="typeValue[0] == 'M' || typeValue[0] == 'I' ">
                    AN &nbsp;
                  </template>
                  <template v-else>
                    A &nbsp;
                  </template>
                    <span class="typed-text" style="color:deeppink;">{{ typeValue }}</span>
                    <span class="blinking-cursor">|</span>
                    <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
                </div>

            </div>
        </div>
    </div>

    <div align="center">

        <div class="pink-rect" v-bind:style="autoColor">
            <img class="bitmoji" src="../../public/bitmoji.png">
            <div class="introduction">
              Welcome to my digital portfolio! <br>
              I am a data and math enthusiast with a passion for solving complex problems.<br>
              I've also had the opportunity to learn about various tech domains, including web development
              which I also used to build this very portfolio.
              <br>
              Do explore my work and accomplishments.

            </div>

            <a href="../assets/RESUME.pdf" download="Resume-Pushpak_Ruhil.pdf">
              <div class="download-resume">
                <p></p>
                Download my resume
              </div>
          </a>
        </div>

    </div>   




  <footBar/>


</template>


<script>

import navBar from './navBar.vue';
import footBar from "@/components/footBar.vue";

export default {
  name: 'HelloWorld',
  components: {
      navBar,
      footBar
  },
  data(){
    return {
        typeValue: "",
      typeStatus: false,
      displayTextArray: ["DATA SCIENTIST", "ML ENGINEER", "TABLEAU DEVELOPER", "DATA ANALYST", "WEB DEVELOPER"],
      typingSpeed: 100,
      erasingSpeed: 100,
      newTextDelay: 2000,
      displayTextArrayIndex: 0,
      charIndex: 0,
      autoColor: {backgroundColor: '#d1deff'}, // ffd3e0


    }
  },
  methods: {

    typeText() {
      if (this.charIndex < this.displayTextArray[this.displayTextArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.displayTextArray[this.displayTextArrayIndex].charAt(
          this.charIndex
        );
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue = this.displayTextArray[this.displayTextArrayIndex].substring(
          0,
          this.charIndex - 1
        );
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.displayTextArrayIndex += 1;
        if (this.displayTextArrayIndex >= this.displayTextArray.length)
          this.displayTextArrayIndex = 0;
        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    },
  },
  created() {
   setTimeout(this.typeText, this.newTextDelay + 200);
  },

  beforeMount(){
    localStorage.currentPage = 'index'
  },



}




</script>


<style scoped>


a:link {
    color: black;
    text-decoration: none;
  }

a:visited {
    color: black;
    text-decoration: none;
  }


a:hover {
  color: deeppink;
  text-decoration: none;
}

a{
  text-decoration: none;
}

.work-header {
  text-align: center;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
  color: #000000;
  font-family: Jura, 'Source Sans Pro';
}

 
.pink-rect {
    height: 550px;
    width: 88.5%;
    margin-top: 20px;
    background: #d1deff;
    color: black;
    border-bottom: 14px solid #000000;
    text-align: center;
    position: relative;
}


.download-resume {
    height: 70px;
    width: 23%;
    position: absolute;
    margin-top: 430px;
    margin-left: 9%;
    /*border-radius: 5px;*/
    background-color: white;
    text-align: center;
    text-transform: uppercase;
    font-family: Jura;
    font-size: 25px;
}

.download-resume:hover{
  color: deeppink;
  /*border: solid deeppink 2px;*/
}

.bitmoji{
    position: absolute;
    top: 27.1%;
    right: 0;
    object-fit: cover;
    vertical-align: top;
    
}

.introduction {
    position: absolute;
    text-align: left;
    margin-left: 9%;
    margin-top: 5%;
    font-family: Chivo;
    font-size: 24px;
    line-height: 55px;
    margin-right: 300px;

}

/* ------- */


@media (max-width: 1700px) and (min-width: 1400px) {
  .introduction{
    font-size: 22px;
  }
  .download-resume{
    font-size: 23px;
    margin-top: 420px;
  }


}

/* FOR MOBILE */
@media (max-width: 1400px) {

    html, body {
      overflow-y: auto;
    }
    
    .pink-rect {
        height: 1400px;
        width: 90%;
        margin-top: 50px;
        background: #ffd3e0;
        color: black;
        border-bottom: 14px solid #000000;
        text-align: center;
        position: relative;
        
    }

    .bitmoji{
        position: absolute;
        top: 71.8%;
        left: 55%;
        transform: translate(-60%, 0%);
        object-fit: cover;
        vertical-align: top;
        
    }


    .introduction {
        position: absolute;
        line-height: 1.7cm;
        text-align: center;
        margin-left: 10%;
        margin-top: 96px;
        font-family: Chivo;
        font-size: 35px;
        margin-right: 10%;
    }

    .download-resume {
        height: 75px;
        width: 50%;
        position: absolute;
        margin-top: 750px;
        margin-left: 25%;
        margin-right: 25%;
        align-items: center;
        background-color: white;
        text-align: center;
        text-transform: uppercase;
        font-family: Jura;
        font-size: 25px;
    }
    .container {
        width: 100%;
        white-space: nowrap;
        height: 80px;
        margin-bottom: -5px;
        margin-top: 25px;
        font-size: 50px;
        display: flex;
        justify-content: left;
        align-items: center;
        flex-wrap: nowrap;
  }
    

}

/* FOR TYPEWRITER */
.container {
  width: 100%;
  height: 80px;
  margin-bottom: -5px;
  margin-top: 18px;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
h1 {
  font-size: 5rem;
  font-weight: normal;
}

/* // Cursor blinking CSS Starts... */
.blinking-cursor {
  font-size: 50px;
  color: #2c3e50;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}
@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
@-moz-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
@-ms-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}
@-o-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: black;
  }
}

</style>



