<template>
  <navBar current-page="projects"/>

  <div class="title">
    Projects
  </div>


  <br>

     <template v-for="(proj, index) in projects" :key="index">
      <div class="flip">
        <div class="front" :id="proj[0]">

        </div>
        <div class="back">
          <div class="cert-head">{{ proj[2] }}</div>
              <div class="cert-date">{{ proj[3] }}</div>
              <div class="cert-desc">{{ proj[4] }}</div>
              <div class="cert-skills">{{ proj[5] }}</div>
              <template v-if="proj[6] != 'skip'">
                <div class="cert-verify">
                  <a :href="proj[6]" target="_blank">Link to Verify</a>
                </div>
              </template>
        </div>
      </div>
    </template>

  <br>

  <router-link :to="{name: 'projectsView'}">
    <button class="cert-next">
      PREVIOUS
    </button>
  </router-link>



<foot-bar />

  <div class="hr-rule-white"></div>
  <a href="#" class="back-to-top" @click="scrollToTop">Back to top</a>
  <div class="hr-rule-white"></div>
</template>


<script>

import navBar from "@/components/navBar";
import footBar from "@/components/footBar";

export default {
  name: 'proj2View',
  data() {
    return {
      projects: [

          [
              "azureML",
              "flex-item-left",
              "Azure ML Studio",
              "May 2021",
              "trained and tested a boosted decision tree model on Microsoft's Azure ML Studio",
              "Skills: Microsoft Azure studio, Machine Learning, Boosted Decision Tree",
              "https://www.coursera.org/projects/azure-machine-learning-studio-pipeline"
          ],
          [
              "MNIST-class",
              "flex-item-right",
              "Classification Model",
              "April 2021",
              "Trained and tested models to predict the digits from the image, accurate upto 93% of the times.",
              "Skills: SVD, SVM, Hyperparameter Tuning, performance Analysis",
              "https://github.com/Ruhil-DS/MNIST-classification"
          ],
          [
              "spamSMS",
              "flex-item-left",
              "Spam SMS Detection model",
              "April 2021",
              "SMS spam classification which classifies an SMS as spam or ham with 97% accuracy.",
              "Skills: Machine Learning, Hyperparameter tuning, performance analysis",
              "https://github.com/Ruhil-DS/Spam-Classifier"
          ],
          [
              "HousingPrice",
              "flex-item-right",
              "Housing Price Prediction",
              "April 2021",
              "Regression model to predict price of houses in California.",
              "Skills: Machine Learning, Hyperparameter tuning, performance analysis",
              "https://github.com/Ruhil-DS/HPP-regression"
          ],
          // [
          //     "ID",
          //     "flex-item-right",
          //     "name",
          //     "date",
          //     "desc",
          //     "skills",
          //     "link"
          // ],


      ]
  }
  },
  components: {
    navBar,
    footBar
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }},
  mounted() {
    localStorage.currentPage = 'proj'
    setTimeout(this.scrollToTop,500)
  }


}
</script>

<style scoped>

#azureML{
  background-image: url('@/assets/projects/azureML.svg');
  background-size: 600px;
}
#MNIST-class{
  background-image: url('@/assets/projects/digitClass.svg');
  background-size: 600px;
}
#spamSMS{
  background-image: url('@/assets/projects/smsSpam.svg');
  background-size: 600px;
}
#HousingPrice{
  background-image: url('@/assets/projects/housingPrice.svg');
  background-size: 600px;
}


.flip {
  position: relative;
}
.flip > .front,
.flip > .back {
  display: block;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 1.5s;
  transition-property: transform, opacity;
}
.flip > .front {
  transform: rotateY(0deg);
}
.flip > .back {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg);
}
.flip:hover > .front {
  transform: rotateY(180deg);
}
.flip:hover > .back {
  opacity: 1;
  transform: rotateY(0deg);
}
.flip.flip-vertical > .back {
  transform: rotateX(-180deg);
}
.flip.flip-vertical:hover > .front {
  transform: rotateX(180deg);
}
.flip.flip-vertical:hover > .back {
  transform: rotateX(0deg);
}

.flip {
  position: relative;
  display: inline-block;
  margin: 10px 10px 10px 10px;
  width: 450px;
}
.flip > .front,
.flip > .back {
  display: block;

  width: inherit;
  background-size: cover !important;
  background-position: center !important;
  height: 330px;
  padding: 1em 2em;

  border-radius: 10px;
}
.flip > .front p,
.flip > .back p {
  font-size: 0.9125rem;
  line-height: 160%;
  color: #999;
}

.back{
  background-image: url("@/assets/bg_img_cert.svg");
}



.hr-rule-white{
  border: 2px solid white;
  display: block;
  width: 880px;
  margin-top: 25px;
}

.hr-rule-white{
  border: 2px solid white;
  display: block;
  width: 880px;
  margin-top: 25px;
}

a{
  text-decoration: none;
}

.back-to-top{
  font-family: Jura, 'Source Sans Pro';
  font-weight: 500;
  font-size: 23px;
  color: black;
  margin-top: 5px;
  margin-bottom: 1%;
  text-align: center;
  margin-left: 0.5%;
  text-transform: uppercase;
}

.back-to-top a{

}

.back-to-top:hover{
  color: deeppink;
}




.title{
  text-align: center;
  font-family: Jura, 'Source Sans Pro';
  color: black;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 2%;

  font-size: 38px;
}




.flex-container {

  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
}

.flex-container a{
  color: black;
  text-decoration: none;
}

.flex-container a:hover{

  color: deeppink;
}


.cert-head{
  font-family: Jura, 'Source Sans Pro';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  margin-top: 10px;
}

.cert-date{
  font-family: Chivo, 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.cert-desc{
  font-family: Chivo, 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.cert-skills{
  font-family: Chivo, 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}

.cert-verify{
  margin-top: 10%;
  text-transform: uppercase;
}

.cert-verify a{
  color: black;
  font-family: Jura, 'Source Sans Pro';
  font-weight: 700;
  font-size: 26px;
  text-decoration: none;
}

.cert-verify a:hover{
  color: black;
  border-bottom: solid black 5px;
  padding: 2%;
}

.cert-next {
  color: black;
  align-items: center;
  align-content: center;
  text-align: center;
  font-size: 20px;
  background: white;
  padding: 10px 25px 10px 25px;
  border: solid black 2px;
  text-decoration: none;
}

.cert-next:hover {
  background: deeppink;
  color: white;
  padding: 12px 27px 12px 27px;
  border: none;
  text-decoration: none;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 1100px) {

  .flex-container {
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
  }

  .cert-next{
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .flip{
    width: 700px;
  }

  .flip > .front,
  .flip > .back {
    height: 500px;
  }

  .cert-head{
    font-size: 36px;
    margin-top: 20px;
  }

  .cert-date{
    font-size: 25px;
    margin-top: 15px;
  }

  .cert-desc{
    font-size: 24px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .cert-skills{
    margin-top: 20px;
    font-size: 24px;

  }

  .cert-verify{
    margin-top: 10%;
  }

  .cert-verify a{
    font-size: 36px;
  }
}
</style>
