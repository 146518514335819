import { createRouter, createWebHistory } from 'vue-router'

import indexView from '@/views/indexView.vue'
import aboutMeView from "@/views/aboutMeView.vue";
import blogsView from "@/views/blogsView";
import certificatesView from "@/views/certificatesView";
import cert2View from "@/views/cert2View";
import projMain from "@/views/projects/projMain";
import proj2View from "@/views/projects/proj2View";
import errorView from "@/views/error.vue";
import testComp from "@/components/testComp.vue";
import coverLetter from "@/components/coverLetter.vue";
import forDeeksha from "@/components/forDeeksha.vue";
import askingDeekshaOut from "@/components/askingDeekshaOut.vue"
const routes = [
  {
      path: '/',
      name: 'indexView',
      meta: {transition: 'fade'},
      component: indexView
  },
    {
      path: '/about-me/',
      name: 'aboutMeView',
      meta: {transition: 'fade'},
      component: aboutMeView
  },
    {
      path: '/blogs',
      name: 'blogsView',
      meta: {transition: 'fade'},
      component: blogsView
  },
  {
      path: '/certificates',
      name: 'certificatesView',
      meta: {transition: 'fade'},
      component: certificatesView
  },
  {
      path: '/certificates/2',
      name: 'cert2View',
      meta: {transition: 'fade'},
      component: cert2View
  },
  {
      path: '/projects',
      name: 'projectsView',
      meta: {transition: 'fade'},
      component: projMain

  },
    {
      path: '/projects/2',
      name: 'proj2View',
      meta: {transition: 'fade'},
      component: proj2View
  },

    {
      path: '/test',
      name: 'testCompView',
      meta: {transition: 'fade'},
      component: testComp

  },
    {
      path: '/cover/:position/:company',
      name: 'CoverView',
      meta: {transition: 'fade'},
      component: coverLetter,
      props: true
  },

    {
      path: '/404',
      name: 'error',
      meta: {transition: 'fade'},
      component: errorView
  },
//     {
//       path: '/shhh-secret-link',
//       name: 'forDeeksha',
//       meta: {transition: 'fade'},
//       component: forDeeksha
//   },
//   {
//     path: '/another-secret-link',
//     name: 'askingDeekshaOut',
//     meta: {transition: 'fade'},
//     component: askingDeekshaOut
// },
    {
        path: '/:catchAll(.*)',
        redirect: '/404'
    }



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
