<template>
  <cert2/>
</template>

<script>
import cert2 from "@/components/cert2.vue";
export default {
  name: "cert2View",
  components:{
    cert2
  }
}
</script>

<style scoped>

</style>