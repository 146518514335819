<template>
<navBar currentPage="aboutMe"/>


  <div class="grid flex-container top-content">

      <div class="col-md-4">

        <div class="pic-align-center">
          <div class="color-bg flip-card">
            <div class="flip-card" ref="flipImgId">
              <div class="flip-card-inner">
                <div class="flip-card-front my-pic">
                  <img src="../assets/me.jpg" alt="Avatar">
                </div>
                <div class="flip-card-back">
                  <img id="magic-pic" src="../assets/me_bit.png" alt="Avatar" style="width:300px;height:300px;">
                </div>
              </div>
            </div>
          </div>
        </div>


    </div>
<!--      <div class="col-md-1"></div>-->
    <div class="col-md-7">
      <div class="pic-text">
        <span id="desktop">OH, HERE'S A WHOLE PAGE ABOUT ME!</span>
        <span id="mobile">ABOUT ME</span>
        <div class="hr-rule"></div>
      </div>
      <div class="my-quote">
        “HUMANS LEARN, <br>
        SO DO MACHINES!”
      </div>
      <div class="my-salutation">
        - Pushpak Ruhil
      </div>
    </div>


  </div>


  <br><br>
  <div class="pink-rect" style="height: 450px;">
    <div class="bring-table">
      <br>
      MY EDUCATION
    </div>
    <br><br><br>
    <div class="container">
          <div class="row">
            <div class="col-lg-3 edu-year">
              2021 - Present
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-5 edu-name">
              Bachelor of Science (B.S.)
              <br>
              <div class="edu-details">
                <div style="text-decoration: underline; font-weight: 900">
                  Data Science and Applications
                </div>
                Indian Institute of Technology, Madras
              </div>
            </div>
          </div>
    </div>

  </div>

  <div class="pink-rect" style="height: 350px; background-color: white; margin-top: 0px">
    <br><br><br>
    <br>
    <div class="container">
          <div class="row">
            <div class="col-lg-3 edu-year">
              2019 - 2023
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-5 edu-name">
              Bachelor of Technology (B.Tech.)
              <br>
              <div class="edu-details">
                <div style="text-decoration: underline; font-weight: 900">
                  Computer Science Engineering
                </div>
                Jamia Hamdard University, Delhi

              </div>
            </div>
          </div>
    </div>

  </div>

  <div class="pink-rect" style="height: 350px; margin-top: 0px">
    <br><br><br>
    <br>
    <div class="container">
          <div class="row">
            <div class="col-lg-3 edu-year">
              2004 - 2019
            </div>
            <div class="col-lg-4"></div>
            <div class="col-lg-5 edu-name">
              Junior and High School
              <br>
              <div class="edu-details">
                <div style="text-decoration: underline; font-weight: 900">
                  PCM and CS
                </div>
                SAM International School,
                <span class="only-desktop">New Delhi</span>
                <div class="only-mobile">
                  New Delhi
                </div>
              </div>
            </div>
          </div>
    </div>

  </div>


  <div class="why-DS">
    WHY DID I CHOOSE DATA SCIENCE?
    <div class="hr-rule"></div>
    <br>
    <div class="answer">
      As a person who is deeply interested in both technical and mathematical-statistical
      concepts, I find data science to be the ideal field for me.
      I relish the opportunity to use my problem-solving skills to address challenging
      issues. The sense of satisfaction and excitement I feel when I witness and participate
      in the process of machine learning and prediction through data is indescribable.
      It has always been my goal to build a career in the
      realm of artificial intelligence and machine learning.
      You could check
      my project by <router-link :to="{name: 'projectsView'}">clicking here.</router-link>
    </div>
  </div>

<!--ANOTHER ONE HERE, TRYING THIS-->

  <div class="pink-rect" style="height: 450px;">
    <div class="bring-table">
      <br>
      PORs and Experience
    </div>
    <br><br><br>
    <div class="container">
          <div class="row">
            <div class="col-lg-4 edu-year">
              Apr 2024 - Present
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-5 edu-name">
              Data Science Intern
              <br>
              <div class="edu-details">
                <div style="text-decoration: underline; font-weight: 900">
                  AiDash
                </div>
                Worked on BNGAI Product of AiDash
              </div>
            </div>
          </div>
    </div>

  </div>

  <div class="pink-rect" style="height: 350px; background-color: white; margin-top: 0px">
    <br><br><br>
    <br>
    <div class="container">
          <div class="row">
            <div class="col-lg-4 edu-year">
              Nov 2023 - July 2024
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-5 edu-name">
              Research Intern
              <br>
              <div class="edu-details">
                <div style="text-decoration: underline; font-weight: 900">
                  IIT Madras
                </div>
                Analysing data for energy consumption at firm level
              </div>
            </div>
          </div>
    </div>

  </div>

  <div class="pink-rect" style="height: 350px; margin-top: 0px">
    <br><br><br>
    <br>
    <div class="container">
          <div class="row">
            <div class="col-lg-4 edu-year">
              Jun 2023 - Oct 2023
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-5 edu-name">
              Teaching Assistant
              <br>
              <div class="edu-details">
                <div style="text-decoration: underline; font-weight: 900">
                  IIT Madras
                </div>
                TA for "Machine Learning Foundations"
              </div>
            </div>
          </div>
    </div>

  </div>

  <div class="pink-rect" style="height: 350px; background-color: white; margin-top: 0px">
    <br><br><br>
    <br>
    <div class="container">
          <div class="row">
            <div class="col-lg-4 edu-year">
              Jun 2023 - Aug 2023
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-5 edu-name">
              Project Mentor
              <br>
              <div class="edu-details">
                <div style="text-decoration: underline; font-weight: 900">
                  IIT Madras
                </div>
                Mentored 85+ students for the project
              </div>
            </div>
          </div>
    </div>

  </div>

  <div class="pink-rect" style="height: 350px; margin-top: 0px">
    <br><br><br>
    <br>
    <div class="container">
          <div class="row">
            <div class="col-lg-4 edu-year">
              Dec 2022 - Present
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-5 edu-name">
              Viva Examiner
              <br>
              <div class="edu-details">
                <div style="text-decoration: underline; font-weight: 900">
                  IIT Madras
                </div>
                Conducted viva-voce for 50+ students
              </div>
            </div>
          </div>
    </div>
  </div>

  <div class="pink-rect" style="height: 350px; background-color: white; margin-top: 0px">
    <br><br><br>
    <br>
    <div class="container">
          <div class="row">
            <div class="col-lg-4 edu-year">
              Aug 2023 - Dec 2023
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-5 edu-name">
              Academic Mentor
              <br>
              <div class="edu-details">
                <div style="text-decoration: underline; font-weight: 900">
                  IIT Madras
                </div>
                Python Mentor (Aug-Sept) <br>
                Database Management Systems Mentor (Sept-Dec)

              </div>
            </div>
          </div>
    </div>

  </div>
  <div class="pink-rect" style="height: 350px; margin-top: 0px">
    <br><br><br>
    <br>
    <div class="container">
          <div class="row">
            <div class="col-lg-4 edu-year">
              Jul 2022 - Aug 2022
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-5 edu-name">
              Python Tutor
              <br>
              <div class="edu-details">
                <div style="text-decoration: underline; font-weight: 900">
                  Freelance
                </div>
                Taught advanced concepts in Python
              </div>
            </div>
          </div>
    </div>
  </div>

  <div class="why-DS">
    ME AND MY LOVE FOR TEACHING
    <div class="hr-rule"></div>
    <br>
    <div class="answer">
      A lot of my POR and experience mentions teaching and mentoring. I love sharing my knowledge with others.
      Not only does it help people I teach, but it helps me get a clearer understanding of the concept.
      Teaching has also helped me with my communication skills. <br> I have taught Python, DBMS, Linear Algebra,
      Optimization and Statistics. Apart from this, I have mentored students with their programming concepts
      related to Python as well as web development technologies like Vue.JS, Flask, Celery, etc.

    </div>
  </div>

<!--  ANOTHER ONE STARTS HERE-->
  <div class="pink-rect">
    <div class="bring-table">
      <br>
      SKILLS
    </div>
    <br><br><br><br>
    <!--SKILLS MARQUEE 1-->
    <div class="position-relative marquee-container d-none d-sm-block">

      <div class="marquee d-flex justify-content-around">

        <span>Python  </span>
        <div> • </div>
        <span class="only-desktop">Machine Learning  </span>
        <span class="only-mobile">ML</span>
        <div> • </div>
        <span>Git  </span>
        <div> • </div>
        <span>Excel  </span>
        <div> • </div>
        <span class="only-desktop">Analytics  </span>
        <div class="only-desktop"> • </div>
        <span>SQL  </span>
         <div> • </div>
        <span>Tableau  </span>
        <div> • </div>
        <span class="only-desktop">Deep Learning </span>
        <span class="only-mobile">DL</span>
      </div>

      <div class="marquee marquee2 d-flex justify-content-around">
        <div> • </div>
        <span>Python  </span>
        <div> • </div>
        <span class="only-desktop">Machine Learning  </span>
        <span class="only-mobile">ML</span>
        <div> • </div>
        <span>Git  </span>
        <div> • </div>
        <span>Excel  </span>
        <div> • </div>
        <span class="only-desktop">Data Analytics  </span>
        <div class="only-desktop"> • </div>
        <span>SQL  </span>
         <div> • </div>
        <span>Tableau  </span>
        <div> • </div>
        <span class="only-desktop">Deep Learning </span>
        <span class="only-mobile">DL</span>
        <div> • </div>
      </div>

    </div>
    <!--SKILLS MARQUEE 1 ENDS HERE-->
    <br><br><br><br>
    <!--SKILLS MARQUEE 2 STARTS HERE-->
    <div class="position-relative marquee-container d-none d-sm-block">
      <div class="marquee-repeat d-flex justify-content-around">
        <div> • </div>
        <span class="only-desktop">RESTful-APIs  </span>
        <span class="only-mobile">APIs</span>
        <div> • </div>
        <span>JAVA  </span>
        <div> • </div>
        <span class="only-desktop">Linux & CLI  </span>
        <span class="only-mobile">Linux</span>
        <div> • </div>
        <span>VueJS  </span>
        <div> • </div>
        <span>HTML  </span>
        <div> • </div>
        <span>CSS  </span>
        <div> • </div>
        <span>JS</span>
        <div> • </div>
        <span>AWS  </span>
        <div> • </div>
        <span>GCP  </span>
        <div> • </div>
        <span>Kafka  </span>
        <div> • </div>
        <span>Spark  </span>


      </div>
      <div class="marquee-repeat marquee2-repeat d-flex justify-content-around">
        <div> • </div>
        <span>RESTful-APIs  </span>
        <div> • </div>
        <span>JAVA  </span>
        <div> • </div>
        <span>Linux & CLI  </span>
        <div> • </div>
        <span>VueJS  </span>
        <div> • </div>
        <span>HTML  </span>
        <div> • </div>
        <span>CSS  </span>
        <div> • </div>
        <span>JS  </span>
        <div> • </div>
        <span>AWS  </span>
        <div> • </div>
        <span>GCP  </span>
        <div> • </div>
        <span>Kafka  </span>
        <div> • </div>
        <span>Spark  </span>


      </div>
    </div>

    <!--SKILLS MARQUEE 2 ENDS HERE-->
  </div>

<!--  ANOTHER ONE ENDS HERE-->


  <div class="why-DS">
    AND WHAT ELSE?
    <div class="hr-rule"></div>
    <br>
    <div class="answer">
      In brief, my interests and activities include:
      a deep love for technology in all its forms, as well as a curiosity
      about ethical hacking and cybersecurity.
      I have a particular passion for hands-on experimentation with technical projects,
      such as setting up a Hackintosh from scratch and creating Arduino-based projects.
      <br>
      In addition to my tech-related pursuits,
      I also enjoy stand-up comedy, astronomy & astrophotography, and photo editing.

    </div>
  </div>

  <div class="pink-rect">

    <div class="bring-table">
      <br>
      WHAT DO I BRING TO THE TABLE?
    </div>
    <br><br><br><br>
      <div class="container" id="table-skills">
          <div class="row">
            <div class="col">
              <span>
                <img src="https://img.icons8.com/external-tanah-basah-glyph-tanah-basah/96/null/external-light-bulb-electrician-tools-and-elements-tanah-basah-glyph-tanah-basah-2.png"/>
                <br><br><br>
                CREATIVE THINKING
              </span>
            </div>
            <div class="col" id="soft-skills-1"></div>

            <div class="col">
            <span>
              <img src="https://img.icons8.com/ios-filled/96/null/leadership.png"/>
              <br><br><br>
              LEADERSHIP
              </span>
            </div>
            <div class="col" id="soft-skills-3"></div>
            <div class="col">
            <span>
              <img src="https://img.icons8.com/external-smashingstocks-glyph-smashing-stocks/96/null/external-Problem-Solving-education-smashingstocks-glyph-smashing-stocks.png"/>
              <br><br><br>
              PROBLEM SOLVING
            </span>
          </div>
            <div class="col" id="soft-skills-2"></div>

            <div class="col">
              <span>
                <img src="https://img.icons8.com/ios-filled/96/null/training.png"/>
                <br><br><br>
              MENTORING
              </span>
            </div>
          </div>
      </div>

  </div>

  <!--  ANOOP'S CREDIT -->

<div class="toast-container position-fixed bottom-0 end-0 p-3" >
  <div id="autoToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="5000">
    <div class="toast-header">
      <strong class="me-auto">Thanks
        <span style="color: deeppink">TheSpecksy</span></strong>

      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body bg-light">

      Thanks to TheSpecksy (Anoop Udayan) for designing this portfolio for me.
      <br>Do check him out on TheSpecksy.com

    </div>
  </div>
</div>


  <footBar></footBar>

<div class="hr-rule-white"></div>
  <a href="#" class="back-to-top" @click="scrollToTop">Back to top</a>

  <div class="hr-rule-white"></div>
</template>

<script>

// PURE JS STARTS HERE

// PURE JS ENDS HERE

import navBar from "@/components/navBar.vue";
import footBar from "@/components/footBar.vue";

export default {
  name: "AboutMe",
  components: {
    navBar,
    footBar
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
    triggerToast() {
      const anoopToast = document.getElementById('autoToast')
      const toast = new bootstrap.Toast(anoopToast)
      toast.show()

    }
  },
  mounted() {
    localStorage.currentPage = 'aboutMe'
    this.triggerToast()
  }
}

</script>


<style scoped>

.toast-body{
  font-size: 18px;
  text-align: left;
}

.flex-container{
  display: flex !important;
}

.back-to-top{
  font-family: Jura, 'Source Sans Pro';
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 5%;
  color: black;
  text-transform: uppercase;
}

.back-to-top:hover{
  color: deeppink;
}


/*FLIP PICTURE STARTS HERE*/
.flip-card {
  background-color: transparent;
  width: 15.625%;
  height: 360px;
  margin-top: 70px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

}


.flip-card-front {
  color: black;
}

.flip-card-back {
  color: transparent;
  transform: rotateY(180deg);
}

.color-bg {
    background-color: #d1deff;
    width: 300px;
    height: 354px;
    transform: translate(39%, 20%);
    position: relative;

}
.color-bg img {
  position: relative;
    right: 208.85%;
    bottom: 25.73%;
}

#magic-pic {
  transform: translate(35%, 45%)
}

/*FLIP PICTURE ENDS HERE*/

a {
    color: deeppink;
    text-decoration: none;

  }


.my-pic {
  position: absolute;
  margin-left: 155.8%;
  margin-top: 60%;
}

.my-pic img  {
  width: 300px;
}

.pic-text{
  text-align: left;
  font-family: Jura;
  font-weight: 700;
  font-size: 40px;
  margin-top: 10%;
  color: black;
  margin-left: 5%;

}

#mobile{
  display: none;
}

.hr-rule:after {
    border: 2px solid rgb(0, 0, 0);
    content: "";
    display: block;
    width: 880px;
    margin-top: 20px;
    margin-bottom: 25px;
    /* border-bottom: 0.5px solid #9E9E9E; */
}

.hr-rule-white{
  border: 2px solid white;
  display: block;
  width: 880px;
  margin-top: 25px;
}

.my-quote{
  text-align: left;
  font-family: Jura;
  font-weight: 700;
  color: black;
  font-size: 78px;
  margin-top: 30px;
  margin-left: 5%;

}

.my-salutation{
  font-family: Jura;
  font-weight: 700;
  color: black;
  font-size: 30px;
  text-transform: uppercase;
  margin-top: 10px;
  text-align: left;
  margin-left: 5%;

}

.edu-year{
  font-family: Jura;
  font-weight: 700;
  font-size: 46px;
  text-align: left;
  position: absolute;
  margin-left: -10%;
  margin-top: 2.3%;

}
.edu-name{
  font-family: Jura;
  font-weight: 700;
  font-size: 31px;
  text-align: left;
  width: 100%;
  margin-left: 60%;

}
.edu-details {
  font-family: Jura;
  font-weight: 400;
  text-align: left;
  font-size: 21px;
  width: 90%;

}

.why-DS{
  text-align: left;
  font-family: Jura;
  font-weight: 900;
  font-size: 26px;
  margin-left: 6.5%;
  margin-top: 6.5%;
  color: black;
}

.why-DS .hr-rule:after {
  width: 550px;
}
.answer{
  font-family: Chivo;
  font-weight: 1;
  font-size: 26px;
  margin-right: 6.5%;
}

/*MARQUEE STARTS*/

.marquee-container {
  height: 35px;
  font-family: Jura, 'Source Sans Pro';
  font-size: 30px;
  overflow: hidden;
  line-height: 35px;
}


  .marquee {
    top: 0;
    left: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    animation: marquee 40s linear infinite;
  }

  .marquee2 {
    animation-delay: 20s;
  }
  b {
    padding-left: 10px;
  }


@keyframes marquee {
  0% {
    left: 100%;
  }
  100% {
    left: -100%
  }
}


/*repeat*/
.marquee-repeat {
    top: 0;
    left: 100%;
    width: 100%;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    animation: marquee-repeat 40s linear infinite;
  }

  .marquee2-repeat {
    animation-delay: 20s;
  }
  b {
    padding-left: 10px;
  }


@keyframes marquee-repeat {
  0% {
    left: -100%;
  }
  100% {
    left: 100%
  }
}

/*MARQUEE ENDS*/


.pink-rect {
    height: 550px;
    width: 100%;
    margin-top: 155px;
    background: #d1deff;
    color: black;
    position: relative;
}



#soft-skills-1{
  margin-right: 15%;
}
#soft-skills-2{
  margin-right: 15%;
}
#soft-skills-3{
  margin-right: 15%;
}

.bring-table {
  text-align: center;
  font-size: 45px;
  font-family: Jura, 'Source Sans Pro';

}

.col span{
  font-family: Chivo;
  font-size: 25px;
  text-align: center;
}

.only-mobile{
  display: none;
}



@media (max-width: 1800px) and (min-width: 1600px) {


  .my-quote{
    margin-left: 12%;
  }
  .my-salutation{
    margin-left: 12%;
  }
  .pic-text{
    margin-left: 12%;
  }

}

@media (max-width: 1600px) and (min-width: 1360px) {
  .my-quote{
    margin-left: 10%;
    font-size: 60px;
  }
  .hr-rule:after{
    width: 700px;
  }
  .my-salutation{
    margin-left: 10%;
  }
  .pic-text{
    margin-left: 10%;
    font-size: 35px;
  }
  .edu-year{
    margin-left: 5%;
    font-size: 41px;
  }

}


/*FOR MOBILE */
@media (max-width: 1400px) {
    .only-desktop{
      display: none;
    }
    .only-mobile{
      display: block;
    }

    #table-skills{
      padding: 5px;
    }

    .col-md-4 {
      width: 100%;

    }

    .col-md-7 {
      width: 100%;
      text-align: center;
    }

    .flex-container{
      flex-direction: column;
    }

    .my-pic {
      position: inherit;
      margin-top: 80px;
    }

    .my-pic img  {
      width: 300px;
    }

    .pic-text{
      text-align: center;
      font-family: Jura;
      font-weight: 700;
      font-size: 40px;
      margin-top: 11%;
      margin-right: 5%;
      color: black;
    }
    #mobile{
      display: block;
      text-align: center;
      font-size: 35px;
    }
    #desktop{
      display: none;
    }
    .hr-rule:after {
      margin-left: 10%;
      width: 80%;
      margin-right: 10%;
      align-content: center;
      align-items: center;
      text-align: center;
    }
    .my-quote{
      text-align: center;
      margin-right: 5%;
    }
    .my-salutation{
      margin-right: 5%;
      text-align: center;
    }
    .why-DS{
      text-align: center;
      font-family: Jura;
      font-weight: 900;
      font-size: 45px;
      margin-left: 0px;
      margin-top: 12%;
      color: black;
    }
    .why-DS .hr-rule:after{
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
    }
    .answer{
      margin-left: 5%;
      font-size: 35px;
      text-align: center;
      margin-right: 5%;
    }
    #soft-skills-1{
      margin-right: 5px;
    }
    #soft-skills-2{
      margin-right: 5px;
    }
    #soft-skills-3{
      margin-right: 5px;
    }


    .color-bg {
      background-color: #d1deff;
      transform: translate(40%, 15%);
      position: relative;
      margin-left: 25%;



  }
  .color-bg img {
      position: relative;
      right: 230%;
      bottom: 22%;
  }

  #magic-pic {
  transform: translate(38%, -75%)
  }

  .edu-year{
    font-family: Jura;
    font-weight: 500;
    font-size: 42px;
    text-align: left;
    position: absolute;
    margin-left: -10%;
    margin-top: 4.5%;
  }
  .edu-name{
    font-family: Jura;
    font-weight: 700;
    font-size: 25px;
    text-align: left;
    white-space: nowrap;
    width: 60%;
    margin-left: 52%;
  }
  .edu-details {
    font-family: Jura;
    font-weight: 400;
    text-align: left;
    white-space: normal;
    font-size: 25px;
    width: 100%;
}

  .marquee{
    animation: marquee 20s linear infinite;

  }
  .marquee2{
    animation-delay: 10s;
  }


  .marquee-repeat{
    animation-direction: reverse !important;
    animation: marquee 20s linear infinite;

  }
  .marquee2-repeat{
    animation-delay: 10s;
  }

  .toast-header{
    font-size: 35px;
  }
  .toast-body{
    font-size: 30px;
    text-align: left;
  }
  .toast{
    width: 100%;
  }
  .bottom-0{
    width: 100% !important;
  }



}
</style>