<template>
<certificates />
</template>

<script>
import certificates from "@/components/certificates";
export default {
  name: "certificatesView",
  components: {
    certificates
  }
}
</script>

<style scoped>

</style>