<template>

  <div id="name-head">
    PUSHPAK RUHIL
  </div>

  <div class="hr-rule"></div>

  <div id="contact-info">
    <p>Pushpak Ruhil</p>
    <p>
      <a href="tel:+91 9354572920">
        +91 9354572920
      </a>
    </p>
    <p>
      <a href="mailto:RuhilPushpak@gmail.com">
        RuhilPushpak@gmail.com
      </a>
    </p>
    <p>
      <a href="https://PushpakRuhil.in/">
        PushpakRuhil.in
      </a>
    </p>
    <p>New Delhi, India</p>
  </div>

  <div id="main-content">
    <p>Dear Hiring Manager, </p>
    <p>I am excited to apply for the position of <strong> {{formattedPosition}} </strong> at <strong> {{formattedCompany}}. </strong>
  With a strong background in academics, positions of responsibility, work experiences, and extra-curricular activities,
  I am confident that I can contribute to the success of your company. </p>
    <br>
    <p>Academic Related Work:</p>
<p>I have an 8.9 CGPA in my B.S. degree while pursuing two degrees simultaneously.
  Additionally, I was one of 17,000 candidates worldwide selected for the Udacity scholarship's phase 1,
  and I have earned numerous certifications including Google's Data Analytics Professional Certification programme and for Tableau.
  These experiences have strengthened my skills in data analysis and visualization, and have given me the
  tools to effectively communicate insights to various stakeholders. </p>
    <br>
    <p>Position of Responsibilities: </p>
<p>I have demonstrated leadership abilities by mentoring over 90 students in DBMS and over 40 students in Python courses.
  I also served as my school's sports captain during my 11th and 12th grades.
  These experiences have allowed me to develop strong communication, teamwork, and problem-solving skills.</p>
    <br>
    <p>Work Experiences: </p>
<p> I have had the opportunity to apply my knowledge and skills as a teaching assistant for
  Modern Application Development 1 and 2 courses, and as a mentor for both DBMS and Python courses.
  In addition, I have taught advanced Python as a freelancer to a student.
  These experiences have allowed me to develop a deep understanding of programming languages and their
  applications in real-world scenarios. </p>
    <br>
    <p> Extra-Curricular Activities: </p>
<p> I am passionate about technology and have set up a fully working Hackintosh.
  I am also an avid astronomy enthusiast, and I like to keep up with current affairs in the field and stargaze in my free time.
In my earlier years, I attended a few workshops related to ethical hacking
  as well as machine learning to deepen my knowledge in these areas.
  I also played soccer to stay active and enhance my teamwork skills. </p>
<br><br>

    <p>
      Overall, my academic achievements, positions of responsibility, work experiences, and extra-curricular activities
      have prepared me well for the above-mentioned position.
      I am excited about the opportunity to contribute my skills and experiences
      to the success of <strong> {{formattedCompany}}. </strong>
    </p>
    <br>
    <p>Thank you for considering my application.</p>
    <br>

    <div id="salutation">
      <p>
        Sincerely,
      </p>

      <p>
        Pushpak Ruhil
      </p>

      <p>
        <a href="https://PushpakRuhil.in/">
          PushpakRuhil.in
        </a>
      </p>

    </div>

  </div>

</template>

<script>



export default {
  name: "coverView",
  props: ['position', 'company'],

  computed: {
    formattedPosition() {
      // computed property to return position name split with hyphen, if needed.

      // Split the words using hyphen as the separator
      let words = this.position.split('-');

      // Capitalize the first letter of each word
      let formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

      // Join the words with a space between them
      return formattedWords.join(' ');
    },
    formattedCompany() {
      // computed property to return company name split with hyphen, if needed.
      let words = this.company.split("-");
      let formattedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
      return formattedWords.join(" ");
    }

  }
}
</script>

<style scoped>
.hr-rule:after {
    border: 1px solid rgb(0, 0, 0);
    content: "";
    display: block;

    margin-top: 20px;
    margin-bottom: 25px;
    /* border-bottom: 0.5px solid #9E9E9E; */
}

#name-head{
  font-family: "Times New Roman";
  font-size: 38px;
  text-align: center;
  margin-top: 70px;
}

#contact-info p{
  text-align: end;
  word-wrap: break-word;
  margin: 0 2cm 0 0;
  font-family: "Arial",serif;
  font-size: 16px;
}
#salutation p{
  margin: 0;
}

p{
  margin: 10px 0 10px 0;

}
#main-content{
  font-family: "Arial",serif;
  font-size: 16px;
  text-align: left;
  margin: 1.36cm 1.9cm 4cm 1.59cm;

}

@media print {

  #name-head{
    margin-top: 0;
  }
  #contact-info{
    margin: 0;
    font-size: 14px;
  }
  #main-content{

    font-size: 14px;
  }
}
</style>