<template>
  <navBar current-page="certificates"/>

  <div class="title">
    My certifications
  </div>


  <br>

   <div class="flex-container">

    <template v-for="(certificate, index) in certificates" :key="index">
      <div class="flip">
        <div class="front">
          <img :src="certificate[3]" class="cert-img" alt="certificate" />
        </div>
        <div class="back">
          <div class="cert-head">{{ certificate[0] }}</div>
              <div class="cert-date">{{ certificate[1] }}</div>
              <div class="cert-desc">{{ certificate[2] }}</div>

              <template v-if="certificate[4] != 'skip'">
                <div class="cert-verify">
                  <a :href="certificate[4]" target="_blank">Link to Verify</a>
                </div>
              </template>
        </div>
      </div>
    </template>



  </div>

  <br>
  <router-link :to="{name: 'certificatesView'}">
      <button class="cert-prev">
      PREVIOUS
      </button>
  </router-link>




<foot-bar />
  <div class="hr-rule-white"></div>
  <a href="#" class="back-to-top" @click="scrollToTop">Back to top</a>
  <div class="hr-rule-white"></div>

</template>


<script>

import navBar from "@/components/navBar";
import footBar from "@/components/footBar";

export default {
  data() {
    return {
      certificates: [
          [
            'Hacker Rank - SQL Intermediate',
            'May 2021',
            'After solving numerous questions on Hacker Rank, gave an exam to get certified.',
            require('@/assets/certificates/sql-inter.png'),
            'https://www.hackerrank.com/certificates/942ff81ca9ee'
          ],
          [
            'Hacker Rank - SQL Basic',
            'May 2021',
            'After solving numerous questions on Hacker Rank, gave an exam to get certified.',
            require('@/assets/certificates/sql-basics.png'),
            'https://www.hackerrank.com/certificates/6cb002e29881'
          ],

          [
            'Tableau Fundamentals',
            'May 2021',
            'Official course from Tableau\'s e-learning platform for the fundamentals of tableau.',
            require('@/assets/certificates/Tableau-fundamental.jpeg'),
            'https://verify.skilljar.com/c/skc5znpsgyhu'
          ],
          [
            'GUVI - Face Recognition app',
            'April 2021',
            'Built a face recognition app in a one-day workshop/course.',
            require('@/assets/certificates/guvi_faceRecog.png'),
            'https://www.guvi.in/verify-certificate?id=ZB27913uz1GK6wM163'
          ],
          [
            'Machine Learning, Stanford',
            'January 2021',
            'Completed the famous Andrew Ng\'s course on machine learning.',
            require('@/assets/certificates/Coursera_ML_A_Ng.png'),
            'https://www.coursera.org/account/accomplishments/verify/CE8QJSDXSL47'
          ],
          [
            'Foundations of Data Science - PadhAI',
            'December 2020',
            'My first Data Science related course that helped me gain statistical and programmatical understandings.',
            require('@/assets/certificates/PadhAI_cert.jpeg'),
            'https://padhai.onefourthlabs.in/certificates/iff2ml8na5'
          ],
          [
            'Machine Learning workshop',
            'December 2020',
            'My first step towards machine learning in this field.',
            require('@/assets/certificates/mliworkshop.jpeg'),
            'skip'
          ],
          [
            'Ethical Hacking Workshop - Kyrion',
            'October 2017',
            'A 2 day workshop on ethical hacking where I learnt about various hacks to protect one self.',
            require('@/assets/certificates/ethicalHacking.png'),
            'skip'
          ],
          // [
          //   'CertName',
          //   'date',
          //   'description',
          //   require('imgURL'),
          //   'verify'
          // ],

      ]
  }
  },
  components: {
    navBar,
    footBar
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  },
  mounted() {
    localStorage.currentPage = 'cert2'
    setTimeout(
    function(){window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })}, 500)
  }


}
</script>

<style scoped>

.cert-img{
  width: 450px;
  height: 330px;
  border-radius: 10px;
  border: black 2px solid;
}

.flip {
  position: relative;
}
.flip > .front,
.flip > .back {
  display: block;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 1.5s;
  transition-property: transform, opacity;
}
.flip > .front {
  transform: rotateY(0deg);
}
.flip > .back {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transform: rotateY(-180deg);
}
.flip:hover > .front {
  transform: rotateY(180deg);
  opacity: 0;
}
.flip:hover > .back {
  opacity: 1;
  transform: rotateY(0deg);
}
.flip.flip-vertical > .back {
  transform: rotateX(-180deg);
}
.flip.flip-vertical:hover > .front {
  transform: rotateX(180deg);
}
.flip.flip-vertical:hover > .back {
  transform: rotateX(0deg);
}

.flip {
  position: relative;
  display: inline-block;
  margin: 15px 15px 15px 15px;
  width: 450px;
}
.flip > .front,
.flip > .back {
  display: block;

  width: inherit;
  background-size: cover !important;
  background-position: center !important;
  height: 330px;
  /*padding: 1em 2em;*/

  border-radius: 10px;
}
.flip > .front p,
.flip > .back p {
  font-size: 0.9125rem;
  line-height: 160%;
  color: #999;
}

.back{
  background-image: url("@/assets/bg_img_cert.svg");
}



.hr-rule-white{
  border: 2px solid white;
  display: block;
  width: 880px;
  margin-top: 25px;
}

.hr-rule-white{
  border: 2px solid white;
  display: block;
  width: 880px;
  margin-top: 25px;
}


a{
  text-decoration: none;
}

.back-to-top{
  font-family: Jura, 'Source Sans Pro';
  font-weight: 500;
  font-size: 23px;
  color: black;
  margin-top: 5px;
  margin-bottom: 1%;
  text-align: center;
  margin-left: 0.5%;
  text-transform: uppercase;
}

.back-to-top a{

}

.back-to-top:hover{
  color: deeppink;
}




.title{
  text-align: center;
  font-family: Jura, 'Source Sans Pro';
  color: black;
  font-weight: 500;
  text-transform: uppercase;
  margin-top: 2%;

  font-size: 38px;
}




.flex-container {

  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;

}

.flex-container a{
  color: black;
  text-decoration: none;
}

.flex-container a:hover{

  color: deeppink;
}


.cert-head{
  font-family: Jura, 'Source Sans Pro';
  text-transform: uppercase;
  font-weight: 700;
  font-size: 22px;
  margin: 20px 10px 0px 10px;
}

.cert-date{
  font-family: Chivo, 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  margin: 10px 10px 10px 10px;
}

.cert-desc{
  font-family: Chivo, 'Source Sans Pro';
  font-size: 16px;
  font-weight: 400;
  margin: 20px 10px 10px 10px;
}

.cert-verify{
  margin-top: 22%;
  text-transform: uppercase;
}

.cert-verify a{
  color: black;
  font-family: Jura, 'Source Sans Pro';
  font-weight: 700;
  font-size: 26px;
  text-decoration: none;
}

.cert-verify a:hover{
  color: black;
  border-bottom: solid black 5px;
  padding: 2%;
}

.cert-prev {
  color: black;
  font-size: 20px;
  background: white;
  padding: 10px 25px 10px 25px;
  border: solid black 2px;
  text-decoration: none;
}
.cert-prev:hover {
  background: deeppink;
  color: white;
  /*padding: 12px 27px 12px 27px;*/
  border: solid deeppink 2px;
  text-decoration: none;
}


/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 1100px) {

 .flex-container {
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
  }

  .cert-next{
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .flip{
    width: 700px;
  }

  .flip > .front,
  .flip > .back {
    height: 530px;
  }

  .cert-img{
    width: 700px;
    height: 530px;
  }


  .cert-head{
    font-size: 36px;
    margin-top: 20px;
  }

  .cert-date{
    font-size: 25px;
    margin-top: 15px;
  }

  .cert-desc{
    font-size: 24px;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 25px;
  }


  .cert-verify{
    margin-top: 28%;
  }

  .cert-verify a{
    font-size: 36px;
  }



}


</style>
