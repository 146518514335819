<template>
      <router-view :key="$route.fullPath" v-slot="{Component}">
        <transition name="slide-fade" mode="out-in">
          <div :key="$route.fullPath">
            <component :is="Component" />
          </div>
        </transition>
      </router-view>
</template>

<script>


export default {
  name: 'App',
}
</script>

<style>
/*.slide-fade-enter-active {*/
/*  transition: all 0.3s ease-out;*/
/*}*/

/*.slide-fade-leave-active {*/
/*  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);*/
/*}*/

/*.slide-fade-enter-from,*/
/*.slide-fade-leave-to {*/
/*  transform: translateX(20px);*/
/*  opacity: 0;*/
/*}*/

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;

}

</style>
